import { alias, object, primitive, serializable } from "serializr";
import { AccountManager } from "./accountManager.model";

export class SupportContacts {
  @serializable(alias("account_manager", object(AccountManager)))
  accountManager?: AccountManager;

  @serializable(alias("general", object(AccountManager)))
  general?: AccountManager;
}
