import React, { FC } from "react";
import { Button, Menu, Drawer } from "antd";
import "../Navbar/navbar.scss";
import AuthContainer from "../../../store/container/AuthContainer";
import Notification from "../Notification";
import { NotificationTypes } from "../../../enums/notificationTypes";
import HomeIcon from "./../../../assets/icons/multi color icons - svg/home.svg";
import HoldingsIcon from "./../../../assets/icons/multi color icons - svg/holdings.svg";
import WalletIcon from "./../../../assets/icons/multi color icons - svg/wallet.svg";
import ReportIcon from "./../../../assets/icons/multi color icons - svg/report.svg";
import ProfileIcon from "./../../../assets/icons/multi color icons - svg/profile.svg";
import ReferIcon from "./../../../assets/icons/multi color icons - svg/refer.svg";
import FAQIcon from "./../../../assets/icons/multi color icons - svg/faq.svg";
import ReachIcon from "./../../../assets/icons/multi color icons - svg/support.svg";
import LogoutIcon from "./../../../assets/icons/multi color icons - svg/logout.svg";
import HomeWhiteIcon from "./../../../assets/icons/multi color icons - svg/home selected.svg";
import HoldingsWhiteIcon from "./../../../assets/icons/multi color icons - svg/holdings selected.svg";
import WalletWhiteIcon from "./../../../assets/icons/multi color icons - svg/wallet selected.svg";
import ReportWhiteIcon from "./../../../assets/icons/multi color icons - svg/report selected.svg";
import ProfileWhiteIcon from "./../../../assets/icons/multi color icons - svg/profile selected.svg";
import ReferWhiteIcon from "./../../../assets/icons/multi color icons - svg/refer selected.svg";
import FAQWhiteIcon from "./../../../assets/icons/multi color icons - svg/faq selected.svg";
import ReachWhiteIcon from "./../../../assets/icons/multi color icons - svg/support selected.svg";
import LogoutWhiteIcon from "./../../../assets/icons/multi color icons - svg/logout selected.svg";
import { MenuOutlined } from "@ant-design/icons";
import {
  HOME,
  HOLDINGS,
  WALLET,
  REPORT,
  REFER,
  PROFILE,
  FAQ,
  REACH_US,
} from "../../../routes/routeConstants/appRoutes";
import * as AppRoutes from "../../../routes/routeConstants/appRoutes";
import { useHistory } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import { AuthState } from "../../../store/reducers/authReducer";

interface NavItems {
  label: string;
  icon?: string;
  activeIcon?: string;
  route: string;
  key: string;
  disabled?: boolean;
}

interface SidebarProps extends AuthState {
  logoutHandler: Function;
  onClose?: Function;
}

const Sidebar: FC<SidebarProps> = (props) => {
  const [visible, setVisible] = useState(false);
  const { logoutHandler, user, onClose = () => {} } = props;

  const history = useHistory();

  const [activeRoute, setActiveRoute] = useState<string>();
  const [logoutRequested, setLogoutRequested] = useState<boolean>(false);
  /* 
  useEffect(() => {}, [window.innerWidth]);
  console.log("window inner width", window.innerWidth); */

  const NavItems: NavItems[] = [
    {
      label: "Home",
      icon: HomeIcon,
      activeIcon: HomeWhiteIcon,
      route: HOME,
      key: HOME,
    },
    {
      label: "Holdings",
      icon: HoldingsIcon,
      activeIcon: HoldingsWhiteIcon,
      route: HOLDINGS,
      key: HOLDINGS,
      disabled: !user?.lastSubscription && !user?.subscription
    },
    {
      label: "Wallet",
      icon: WalletIcon,
      activeIcon: WalletWhiteIcon,
      route: WALLET,
      key: WALLET,
      disabled: !user?.lastSubscription && !user?.subscription
    },
    {
      label: "Report",
      icon: ReportIcon,
      activeIcon: ReportWhiteIcon,
      route: REPORT,
      key: REPORT,
      disabled: true,
    },
    {
      label: "My Profile",
      icon: ProfileIcon,
      activeIcon: ProfileWhiteIcon,
      route: PROFILE,
      key: PROFILE,
    },
    {
      label: "Invite Friends",
      icon: ReferIcon,
      activeIcon: ReferWhiteIcon,
      route: REFER,
      key: REFER,
    },
    {
      label: "FAQ",
      icon: FAQIcon,
      activeIcon: FAQWhiteIcon,
      route: FAQ,
      key: FAQ,
      // disabled: true
    },
    {
      label: "Contact manager",
      icon: ReachIcon,
      activeIcon: ReachWhiteIcon,
      route: REACH_US,
      key: REACH_US,
    }
  ];

  history.listen((data) => {
    setActiveRoute(data.pathname);
  });

  useEffect(() => {
    setActiveRoute(history.location.pathname);
  }, []);

  const handleLogout = async () => {
    setLogoutRequested(true);
    try {
      await logoutHandler();
      history.push(AppRoutes.LOGIN);
    } catch (error) {}
  };

  const showDrawer = (nav: NavItems) => {
    history.push(nav.route);
    onClose();
  };
  /*  const onClose = () => {
    setVisible(false);
  }; */
  return (
    <>
      <div className="sidebar__container">
        <div className="sidebar__logo"></div>
        <div className="sidebar__items">
          {NavItems.map((nav) => {
            return (
              <div
                key={nav.key}
                className={`sidebar__items--item ${
                  activeRoute === nav.route ? " active" : ""
                } ${nav.disabled ? "disabled" : ""}
            `}
                onClick={() => showDrawer(nav)}
              >
                <img
                  src={activeRoute === nav.route ? nav.activeIcon : nav.icon}
                  alt={nav.label}
                />
                <span>{nav.label}</span>
              </div>
            );
          })}
          <Button
            className="sidebar__items--item"
            onClick={handleLogout}
            disabled={logoutRequested}
          >
            <img src={LogoutIcon} alt="logout" />
            Logout
          </Button>
          <div className="sidebar__items--item animator"></div>
        </div>
      </div>
    </>
  );
};

export default AuthContainer(Sidebar);
