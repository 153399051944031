import { alias, primitive, serializable } from "serializr";

export class AccountManager {
  @serializable(alias("name", primitive()))
  name?: string;
  @serializable(alias("email", primitive()))
  email?: string;
  @serializable(alias("phone", primitive()))
  phone?: string;
}
