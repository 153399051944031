import React, { useEffect } from "react";
import PageHeader from "../../../shared/components/PageHeader";
import { Col, Row, Space } from "antd";
import "./contactManager.scss";
import ReachWhiteIcon from "./../../../assets/icons/reachus-blue.svg";
import { ContactManagerServices } from "../../../services/ContactManager/contactManager.service";
import MailBox from "../../../assets/icons/mail-box.svg";
import PhoneIcon from "../../../assets/icons/phone.svg";

const ContactManager = () => {
  const { getContactDetails, supprtContacts, loading } =
    ContactManagerServices();

  useEffect(() => {
    getContactDetails();
  }, []);

  return (
    <>
      <PageHeader title="Contact account manager" />
      <div className="account-manager__container">
        <Row
          justify="space-between"
          className="account-manager__container--info-bar"
        >
          <Col xs={24} sm={12} lg={8}>
            <div className="account-manager__container--icon-container">
              <div className="account-manager__container--icon-container--icon-box">
                <img src={ReachWhiteIcon} alt="support-icon" />
              </div>
              <div>
                <div className="account-manager__container--info--key--name">
                  Account Manager
                </div>
                <div className="account-manager__container--info--value--name">
                  {supprtContacts?.accountManager?.name ??
                    supprtContacts?.general?.name}
                </div>
              </div>
            </div>
          </Col>
          <Col xs={24} sm={12} lg={8}>
            <div className="account-manager__container--info--key">
              <Space align="start">
                <span>
                  <img src={MailBox} alt="mail-box" sizes="8" />
                </span>
                <span>Email</span>
              </Space>
            </div>
            <div className="account-manager__container--info--value">
              {supprtContacts?.accountManager?.email ??
                supprtContacts?.general?.email}
            </div>
          </Col>
          <Col xs={24} sm={12} lg={8}>
            <div className="account-manager__container--info--key">
              <Space>
                <img src={PhoneIcon} alt="" />
                Phone
              </Space>
            </div>
            <div className="account-manager__container--info--value">
              {supprtContacts?.accountManager?.phone ??
                supprtContacts?.general?.phone}
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ContactManager;
