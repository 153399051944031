import { alias, list, primitive, serializable } from "serializr";

export class Share {
  @serializable(alias("unit", primitive()))
  unit?: number;

  @serializable(alias("phone", primitive()))
  mobileNumber?: number;

  @serializable(alias("dial_code", primitive()))
  dialCode?: number = 254;

  @serializable(alias("status_url", primitive()))
  dynamicUrl?: string;
}

export class ShareUnit {
  @serializable(alias("unit", primitive()))
  unit?: number;

  @serializable(alias("amount", primitive()))
  amount?: number;
}

export class ShareBreakdown {
  @serializable(alias("unit", primitive()))
  unit?: number;

  @serializable(alias("amount", primitive()))
  amount?: number;

  @serializable(alias("breakdown", list(list(primitive()))))
  breakdown?: string[][];

  @serializable(alias("amount_needed", primitive()))
  amountNeeded?: number;
}

export class ShareHistory {
  @serializable(alias("date", primitive()))
  date?: string;

  @serializable(alias("status", primitive()))
  status?: string;

  @serializable(alias("shares", primitive()))
  shares?: number;

  @serializable(alias("total_value", primitive()))
  totalValue?: number;

  @serializable(alias("order_id", primitive()))
  orderId?: number;

  @serializable(alias("unit_price", primitive()))
  unitPrice?: number;
}

export class SingleShareCalculation {
  @serializable(alias("current_price", primitive()))
  currentPrice?: number;

  @serializable(alias("buy_service_charge_percent", primitive()))
  buyPercent?: number;

  @serializable(alias("sell_service_charge_percent", primitive()))
  sellPercent?: number;
}

export class currentValuation {
  @serializable(alias("user_id", primitive()))
  userId?: number;

  @serializable(alias("current_share_price", primitive()))
  currentSharePrice?: number;

  @serializable(alias("current_service_charge_percent", primitive()))
  currentServiceChargePercent?: number;

  @serializable(alias("current_valuation", primitive()))
  currentValuation?: number;

  @serializable(alias("holdings", primitive()))
  holdings?: number;

  @serializable(alias("total_investment", primitive()))
  totalInvestment?: number;

  @serializable(alias("dividends_earned", primitive()))
  dividendsEarned?: number;

  @serializable(alias("capital_appreciation", primitive()))
  capitalAppreciation?: number;
  @serializable(alias("returns_percent", primitive()))
  returnsPercent?: number;
}
