import { useState } from "react";
import axiosInstance from "../../interceptor/axiosInstance";
import { ApiRoutes } from "../../routes/routeConstants/apiRoutes";
import { deserialize } from "serializr";
import { SupportContacts } from "../../models/AccountManager/supportContact.model";
import { getGeneratedNameForNode } from "typescript";

export const ContactManagerServices = () => {
  const [loading, setLoading] = useState(true);
  const [supprtContacts, setSupportContacts] = useState<SupportContacts>();

  const getContactDetails = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get(ApiRoutes.SUPPORT_CONTACTS);
      const data = deserialize(SupportContacts, response?.data["data"]);
      setSupportContacts(data);
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    supprtContacts,
    getContactDetails,
  };
};
